//bq vimeo
.bq_vimeo {
  background: #000;
  height: 560px;
  width: 100%;

  @media (max-width: 767px) {
    height: 215px;
  }
}
.bq_vimeo_lazyload_cover {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.bq_vimeo.playing > *:not(.bq_vimeo_container) {
  display: none;
}
.bq_vimeo_play_btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.bq_vimeo_container {
  display: block;
  width: 100%;
  height: 100%;
}

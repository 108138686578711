@import "../../styles/bootstrap/scss/functions";
@import "../../styles/bootstrap/scss/variables";
@import "../../styles/bootstrap/scss/mixins";
@import "../../styles/bootstrap/scss/utilities";
@import "../../styles/scss/variables";

.gridLayout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1px;
  grid-auto-rows: minmax(200px, auto);
  grid-auto-flow: dense;
  padding: 1px;
}

.gridItem {
  padding: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  // text-transform: uppercase;
  color: #fff;
}

.spanSingle {
  grid-column-end: span 3;
  grid-row-end: span 1;
}

.spanDouble {
  grid-column-end: span 3;
  grid-row-end: span 2;
}

.OverlaySection {
  position: relative;
  padding: 3rem;
  min-height: 248px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 20.24%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
  
  > * {
    position: relative;
  }
  a {
    font-weight: bold;
    text-transform: uppercase;
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    padding: 1.5rem;
  }
}
.OverlaySectionWithoutGradient {
  position: relative;
  padding: 3rem;
  min-height: 248px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  
  > * {
    position: relative;
  }
  a {
    font-weight: bold;
    text-transform: uppercase;
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    padding: 1.5rem;
  }
}
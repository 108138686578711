@import "../../styles/bootstrap/scss/functions";
@import "../../styles/bootstrap/scss/variables";
@import "../../styles/bootstrap/scss/mixins";
@import "../../styles/bootstrap/scss/utilities";
@import "../../styles/scss/variables";

.OverlaySection {
  position: relative;
  padding: 3rem;
  min-height: 248px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 53.24%,
      rgba(0, 0, 0, 0.76) 100%
    );
  }
  > * {
    position: relative;
  }
  a {
    font-weight: bold;
    text-transform: uppercase;
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    padding: 1.5rem;
  }
}

.BannerSection {
  color: $white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  .GradientContainer {
    min-height: 60vh;
  }
  p {
    font-size: 1.125rem;
  }
  h1 {
    font-size: 5rem;
    font-family: $fontFamilyBlack;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 2.6rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .GradientContainer {
      min-height: 90vh;
    }
  }
}

.InnerTabs {
  background: $tabInnerBg;
  color: $secondary;
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 1.5rem;
    small {
      font-size: 1.125rem;
      line-height: 1.85rem;
    }
  }

  @media (max-width: 767px) {
    background-color: transparent;
    .form-group label {
      display: none;
    }
  }
}

.BannerTabs {
  @media (max-width: 767px) {
    background-color: var(--bs-info);
  }
}

@media (max-width: 767px) {
  .section-sm-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .section-sm-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.HeroInnerImageOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media (max-width: 767px) {
  .imageContainer {
    margin: 0 1rem; /* Adjust margins as needed */
    overflow: hidden;
    height: 350px; /* Adjust the height as needed */
    position: relative;
  }

  .imageWrapper {
    position: absolute; /* Position the image absolutely within the container */
    top: 50%; /* Position the top of the image at the vertical center of the container */
    left: 0; /* Align the left edge of the image with the left edge of the container */
    transform: translateY(
      -40%
    ); /* Move the image upward by half of its height to center it vertically */
    width: 100%; /* Ensure the image fills the container horizontally */
  }

  .imageWrapper img {
    width: 100%;
    height: auto;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

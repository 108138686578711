@import "../../styles/scss/variables";

.bannerSection {
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  .gradient-container {
    height: 690px;
  }
  p {
    font-size: 1.125rem;
  }
  h1 {
    font-size: 5rem;
    font-family: $fontFamilyBlack;
  }

}

.polygonWt {
  position: absolute;
  display: inline-block;
  bottom: -1px;
  left: 0;
  background-color: #142b35;
  clip-path: polygon(0 80%, 100% 10%, 100% 100%, 0% 100%);
  height: 79px;
  width: 100%;
  opacity: 0.8;
  z-index: 1000;
  text-align: right;
  color: #fff;
}

// responsive stuff

// big tablets
@media (max-width: 991px) {
  .bannerSection {
    height: 285px !important;
    background-size: contain;
  }
}

// small tablets - big phones
@media (max-width: 767px) {
  .bannerSection {
    height: 125px !important;
    background-size: contain;
  }
}

//mobile
@media (max-width: 479px) {
}

.mobileBannerSection {
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
}